const bookingsAddNewServicePanel = 'bookings/scheduler/owner/offerings/wizard';

const bookingsDashboardPanel = 'bookings/scheduler/owner/offerings';

export async function openBookingsDashboardAddNewService(
  editorSdkAdapter,
  editorSDK,
  appToken,
) {
  await editorSDK.editor.openDashboardPanel(appToken, {
    url: bookingsAddNewServicePanel,
    closeOtherPanels: false,
  });
  editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE');
}
export async function openManageBookings(
  editorSdkAdapter,
  editorSDK,
  appToken,
) {
  await editorSDK.editor.openDashboardPanel(appToken, {
    url: bookingsDashboardPanel,
    closeOtherPanels: false,
  });
  editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE');
}
