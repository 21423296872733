import { EditorActions } from '../../utils/editor-actions';
import {
  AppDescriptor,
  CustomActionConfig,
} from '@wix/platform-editor-sdk/js/manifest';
import { DeafultOptions } from './app-descriptor.const';
import { Translations } from '../../utils/translations';

export default function createAppDescriptor(
  actions: EditorActions,
  isPremium: boolean,
  translation: Translations,
): AppDescriptor {
  const manageBookings: Partial<CustomActionConfig> = {
    title: actions.manageBookingsAction.label,
    actionId: actions.manageBookingsAction.actionId,
  };
  const createAService: Partial<CustomActionConfig> = {
    title: actions.createAService.label,
    actionId: actions.createAService.actionId,
  };
  const openAddPanel: CustomActionConfig = {
    title: actions.openAddPanel.label,
    actionId: actions.openAddPanel.actionId,
    icon: 'appManager_addElementsAction',
    type: 'editorActions',
  };
  const openPagesPanel: CustomActionConfig = {
    title: actions.openPagesPanel.label,
    actionId: actions.openPagesPanel.actionId,
    icon: 'appManager_pagesAction',
    type: 'editorActions',
  };
  const upgrade = {
    upgradeType: DeafultOptions.SITE_UPGRADE as DeafultOptions.SITE_UPGRADE,
    upgradeText: translation.t('bookings-descriptor.actions.upgrde-text'),
    upgradeLinkText: translation.t(
      'bookings-descriptor.actions.upgrde-link-text',
    ),
  };
  return {
    mainActions: [
      manageBookings as CustomActionConfig,
      createAService as CustomActionConfig,
    ],
    customActions: [openAddPanel, openPagesPanel],
    defaultActions: {
      learnMoreKB: DeafultOptions.LEARN_MORE_ID,
      ...(!isPremium ? { upgrade } : {}),
    },
  };
}
